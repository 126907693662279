<template>
  <div class="add-kit">
    <!-- User Selection -->
    <UserSelection
      :communityId="communityId"
      label="Usuário para o Kit"
      @user-loaded="handleUserLoaded"
      @user-selected="handleUserSelected"
    />

    <!-- Kit Selection -->
    <div class="field mt-4">
      <label class="label">Selecionar Kit</label>
      <div class="control">
        <div class="field" v-for="(kit, kitId) in kits" :key="kitId">
          <label class="radio">
            <input type="radio" v-model="selectedKit" :value="kitId" />
            {{ kit.name }} ({{ kit.ads.length }} anúncios)
          </label>
        </div>
      </div>
    </div>

    <!-- Preview Ads -->
    <!-- Preview Ads -->
    <div v-if="selectedKit" class="mt-4">
      <h3 class="title is-5">Anúncios que serão criados:</h3>

      <div class="notification is-info is-light mb-4">
        <p>
          Todos os anúncios serão criados para:
          <strong>{{ selectedUserData?.displayName || currentUserData?.displayName }}</strong>
        </p>
        <p class="mt-2">
          <small>Desmarque os anúncios que você não deseja incluir neste kit.</small>
        </p>
      </div>

      <KitPreview :ads="previewAds" @selection-changed="handleAdSelectionChange" />

      <div class="notification is-light mt-4">
        <p>
          <strong>{{ selectedAds.length }}</strong> de {{ previewAds.length }} anúncios selecionados
        </p>
      </div>
    </div>

    <!-- Submit Button -->
    <div class="field mt-4">
      <div class="control">
        <button
          class="button is-primary"
          :class="{ 'is-loading': isLoading }"
          :disabled="!isValid"
          @click="createKitAds"
        >
          Criar Anúncios
        </button>
        <button class="button is-light ml-2" @click="$emit('cancel')">Cancelar</button>
      </div>
    </div>
  </div>
</template>

<script>
import { kits } from './kits/kits'
import UserSelection from './widgets/UserSelection.vue'
import KitPreview from './widgets/KitPreview.vue'
import { db, serverTimestamp } from '@/firecontainer'
import { storageService } from '@/services/storageService'

export default {
  name: 'AddKit',
  components: {
    UserSelection,
    KitPreview,
  },
  props: {
    communityId: {
      type: String,
      required: true,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selectedKit: '',
      currentUserId: null,
      currentUserData: null,
      selectedUserId: null,
      selectedUserData: null,
      previewAds: [],
      kits: kits,
      selectedAds: [],
    }
  },
  computed: {
    isValid() {
      return this.selectedKit && (this.selectedUserData || this.currentUserData) && this.selectedAds.length > 0
    },
  },
  watch: {
    selectedKit: {
      immediate: true,
      handler(newKit) {
        if (newKit && kits[newKit]) {
          const kitData = kits[newKit]
          this.previewAds = kitData.ads.map((ad) => {
            // Create preview with local image files
            const previewAd = {
              ...ad,
              id: Math.random().toString(36).substring(7),
              owner: this.selectedUserData?.displayName || this.currentUserData?.displayName,
              createdAt: new Date(),
            }

            // Add preview images if available
            if (ad.imageFiles && ad.imageFiles.length > 0) {
              previewAd.previewImages = ad.imageFiles
            }

            return previewAd
          })
          // By default, all ads are selected
          this.selectedAds = [...this.previewAds]
        } else {
          this.previewAds = []
          this.selectedAds = []
        }
      },
    },
  },
  methods: {
    handleUserLoaded({ userId, userData }) {
      this.currentUserId = userId
      this.currentUserData = userData
    },

    handleAdSelectionChange(selectedAds) {
      this.selectedAds = selectedAds
    },

    handleUserSelected({ userId, userData }) {
      this.selectedUserId = userId
      this.selectedUserData = userData
    },

    // Convert a URL path to a File object
    async urlToFile(url, filename) {
      try {
        const response = await fetch(url)
        const blob = await response.blob()
        return new File([blob], filename, { type: blob.type })
      } catch (error) {
        console.error('Error converting URL to File:', error)
        throw error
      }
    },

    async createKitAds() {
      if (!this.isValid || !kits[this.selectedKit] || this.selectedAds.length === 0) return

      this.$emit('update:isLoading', true)

      try {
        const userData = this.selectedUserData || this.currentUserData
        const userId = this.selectedUserId || this.currentUserId

        // Create all ads in batch
        const batch = db.batch()
        const adsCollection = db.collection('communities').doc(this.communityId).collection('ads')

        // Process each ad
        for (const ad of this.selectedAds) {
          const newAdRef = adsCollection.doc()

          // Upload images if available
          let imageUrls = []
          if (ad.imageFiles && ad.imageFiles.length > 0) {
            // Process each image file
            const imagePromises = ad.imageFiles.map(async (imagePath, index) => {
              try {
                // Convert image path to file
                const file = await this.urlToFile(imagePath, `kit_${Date.now()}_${index}.jpg`)

                // Compress the image
                const compressedFile = await storageService.compressImage(file)

                // Upload to Firebase Storage
                return await storageService.uploadFile(compressedFile, this.communityId, userId)
              } catch (error) {
                console.error('Error processing image:', error)
                return null
              }
            })

            // Wait for all uploads to complete
            const results = await Promise.all(imagePromises)
            imageUrls = results.filter((url) => url !== null)
          }

          // Add the ad to the batch
          batch.set(newAdRef, {
            title: ad.title,
            description: ad.description,
            address: ad.address,
            category: ad.category,
            subcategory: ad.subcategory,
            type: ad.type,
            mode: ad.mode,
            paymentTypes: ad.paymentTypes,
            price: ad.price,
            owner: userData.displayName || 'Não id',
            ownerId: userId,
            contactInfo: userData.contactInfo || 'Não informado',
            createdAt: serverTimestamp(),
            updatedAt: serverTimestamp(),
            images: imageUrls,
            expiresAt: ad.expiresAt || null,
          })
        }

        await batch.commit()
        console.log('Kit criado com sucesso!')
        this.$emit('kit-created')
      } catch (error) {
        console.error('Error creating kit:', error)
      } finally {
        this.$emit('update:isLoading', false)
      }
    },
  },
}
</script>
