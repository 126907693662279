// src/services/storage.service.js
import { storage } from '@/firecontainer'
import { v1 as uuidv1 } from 'uuid'

class StorageService {
  /**
   * Uploads a file to Firebase Storage
   * @param {File} file - The file to upload
   * @param {string} communityId - The community ID
   * @param {string} userId - The user ID
   * @returns {Promise<string>} - The download URL of the uploaded file
   */
  async uploadFile(file, communityId, userId) {
    try {
      // Get the file extension from the original file name
      const extension = file.name.split('.').pop()
      // Create a new file name using uuid v1 and the extension
      const newFileName = `${uuidv1()}.${extension}`
      const uploadPath = `${communityId}/${userId}/${newFileName}`
      console.log(`[StorageService] Uploading file to ${uploadPath}`)
      
      const storageRef = storage.ref(uploadPath)
      const snapshot = await storageRef.put(file)
      const downloadURL = await snapshot.ref.getDownloadURL()
      
      return downloadURL
    } catch (error) {
      console.error('[StorageService] Error uploading file:', error)
      throw new Error('Failed to upload file')
    }
  }

  /**
   * Deletes a file from Firebase Storage
   * @param {string} fileURL - The URL of the file to delete
   */
  async deleteFile(fileURL) {
    try {
      // Skip if URL is empty or undefined
      if (!fileURL) {
        console.warn('[StorageService] No file URL provided for deletion')
        return
      }
  
      const fileRef = storage.refFromURL(fileURL)
      await fileRef.delete()
      console.log(`[StorageService] File deleted successfully: ${fileURL}`)
    } catch (error) {
      // Log error but don't throw to allow deletion of other files to continue
      console.error('[StorageService] Error deleting file:', error)
      
      // Only throw if it's not a "file not found" error
      if (!error.message.includes('Object not found')) {
        throw new Error('Failed to delete file')
      }
    }
  }

  /**
   * Compresses an image before upload
   * @param {File} file - The image file to compress
   * @returns {Promise<File>} - The compressed image file
   */
  async compressImage(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      
      reader.onload = (event) => {
        const img = new Image()
        img.src = event.target.result
        
        img.onload = () => {
          const canvas = document.createElement('canvas')
          const ctx = canvas.getContext('2d')
          
          // Target width and height (maintain aspect ratio)
          let width = img.width
          let height = img.height
          
          // If image is larger than 1000px in either dimension, scale it down
          if (width > 1000 || height > 1000) {
            const ratio = width / height
            if (width > height) {
              width = 1000
              height = width / ratio
            } else {
              height = 1000
              width = height * ratio
            }
          }
          
          canvas.width = width
          canvas.height = height
          
          ctx.drawImage(img, 0, 0, width, height)
          
          // Convert to file with reduced quality
          canvas.toBlob(
            (blob) => {
              const compressedFile = new File([blob], file.name, {
                type: 'image/jpeg',
                lastModified: Date.now(),
              })
              resolve(compressedFile)
            },
            'image/jpeg',
            0.6 // Compression quality (0.6 = 60% quality)
          )
        }
      }
      
      reader.onerror = (error) => reject(error)
    })
  }
}

export const storageService = new StorageService()
