// src/components/widgets/UserSelection.vue
<template>
  <div>
    <div class="field">
      <label class="label">{{ label || 'Usuário' }}</label>
      <div class="control">
        <div class="is-flex is-align-items-center">
          <div class="box mb-0 flex-grow-1">
            <strong>{{ selectedUserData?.displayName || currentUserData?.displayName || 'Carregando...' }}</strong>
            <br />
            <small class="has-text-grey">ID: {{ selectedUserId || currentUserId }}</small>
          </div>
          <button type="button" class="button is-info is-light ml-2" @click="showUserSelectModal = true">
            <span class="icon">
              <i class="material-icons">person</i>
            </span>
            <span>Alterar</span>
          </button>
        </div>
      </div>
    </div>

    <!-- User Selection Modal -->
    <div class="modal" :class="{ 'is-active': showUserSelectModal }">
      <div class="modal-background" @click="closeUserSelectModal"></div>
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">Selecionar Usuário</p>
          <button class="delete" @click="closeUserSelectModal"></button>
        </header>
        <section class="modal-card-body">
          <div class="field">
            <label class="label">ID do Usuário</label>
            <div class="control">
              <input
                v-model="tempUserId"
                class="input"
                type="text"
                placeholder="Digite o ID do usuário"
                @input="handleUserIdInput"
              />
            </div>
          </div>

          <div v-if="userSelectError" class="notification is-danger">
            {{ userSelectError }}
          </div>

          <div v-if="userPreview" class="box">
            <h4 class="title is-5">Dados do Usuário:</h4>
            <p><strong>Nome:</strong> {{ userPreview.displayName }}</p>
            <p><strong>Email:</strong> {{ userPreview.email }}</p>
            <p><strong>Contato:</strong> {{ userPreview.contactInfo }}</p>
          </div>
        </section>
        <footer class="modal-card-foot">
          <button class="button is-success" @click="confirmUserSelection" :disabled="!userPreview">Confirmar</button>
          <button class="button" @click="closeUserSelectModal">Cancelar</button>
        </footer>
      </div>
    </div>
  </div>
</template>

<script>
import { db } from '@/firecontainer'
import _ from 'lodash'

export default {
  name: 'UserSelection',
  props: {
    communityId: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      currentUserId: this.$store.getters.user.data.uid,
      currentUserData: null,
      selectedUserId: null,
      selectedUserData: null,
      showUserSelectModal: false,
      userSelectError: null,
      tempUserId: '',
      userPreview: null,
    }
  },
  async created() {
    try {
      const userDoc = await db.collection('profiles').doc(this.currentUserId).get()
      if (userDoc.exists) {
        this.currentUserData = userDoc.data()
        this.$emit('user-loaded', {
          userId: this.currentUserId,
          userData: this.currentUserData,
        })
      }
    } catch (error) {
      console.error('Error fetching current user data:', error)
    }
  },
  methods: {
    closeUserSelectModal() {
      this.showUserSelectModal = false
      this.tempUserId = ''
      this.userSelectError = null
      this.userPreview = null
    },

    handleUserIdInput: _.debounce(async function () {
      if (!this.tempUserId.trim()) {
        this.userPreview = null
        this.userSelectError = null
        return
      }

      try {
        const communityDoc = await db.collection('communities').doc(this.communityId).get()
        const communityData = communityDoc.data()

        if (!communityData.members.includes(this.tempUserId)) {
          this.userSelectError = 'Usuário não é membro desta comunidade'
          this.userPreview = null
          return
        }

        const userDoc = await db.collection('profiles').doc(this.tempUserId).get()

        if (!userDoc.exists) {
          this.userSelectError = 'Usuário não encontrado'
          this.userPreview = null
          return
        }

        const userData = userDoc.data()
        this.userPreview = {
          uid: this.tempUserId,
          displayName: userData.displayName || 'Sem nome',
          email: userData.email,
          contactInfo: userData.contactInfo || 'Não informado',
        }
        this.userSelectError = null
      } catch (error) {
        console.error('Error validating user:', error)
        this.userSelectError = 'Erro ao validar usuário'
        this.userPreview = null
      }
    }, 500),

    confirmUserSelection() {
      if (this.userPreview) {
        this.selectedUserId = this.userPreview.uid
        this.selectedUserData = this.userPreview
        this.$emit('user-selected', {
          userId: this.selectedUserId,
          userData: this.selectedUserData,
        })
        this.closeUserSelectModal()
      }
    },
  },
}
</script>
