<template>
  <section class="section">
    <UserInfo v-if="user.data.superAdmin" />
    <ListFeedback :feedback="feedbackItems" :formatPhoneNumber="formatPhoneNumber" @update-status="updateStatus" />
  </section>
</template>

<script>
import { mapGetters } from 'vuex'
import { db, serverTimestamp } from '@/firecontainer'
import { formatPhoneNumber } from '@/utils/phoneUtils'
import UserInfo from '../core/UserInfo.vue'
import ListFeedback from './ListFeedback.vue'

export default {
  name: 'FeedbackPage',
  components: { UserInfo, ListFeedback },
  data() {
    return {
      isLoading: false,
      feedbackItems: [],
    }
  },
  computed: {
    ...mapGetters({
      user: 'user',
    }),
  },
  watch: {
    user: {
      immediate: true,
      handler: function () {
        this.fetchFeedback()
      },
    },
  },
  methods: {
    formatPhoneNumber,

    async fetchFeedback() {
      try {
        if (this.user.data.superAdmin) {
          const querySnapshot = await db.collection('feedback').orderBy('createdAt', 'desc').get()

          this.feedbackItems = querySnapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
            // Set default status if it doesn't exist
            status: doc.data().status || 'open',
          }))
        } else {
          throw 'Not valid user'
        }
      } catch (e) {
        console.error('Error fetching feedback:', e)
      }
    },

    async updateStatus(item) {
      try {
        const newStatus = !item.status || item.status === 'open' ? 'closed' : 'open'

        await db.collection('feedback').doc(item.id).update({
          status: newStatus,
          updatedAt: serverTimestamp(),
        })

        // Refresh the feedback list
        await this.fetchFeedback()
      } catch (error) {
        console.error('Error updating feedback status:', error)
      }
    },
  },
}
</script>
