<template>
  <div>
    <div class="tile is-ancestor is-vertical">
      <div v-for="community in communities" :key="community.id" class="tile is-parent">
        <article class="tile is-child box">
          <!-- Replace level with custom class for consistent left alignment -->
          <div class="community-header mb-2">
            <div>
              <p class="title is-4">{{ community.name }}</p>
              <p class="subtitle is-6">
                {{ community.address }}
                <span class="tag is-primary is-light ml-2" title="Membros">
                  <span class="icon is-small mr-1">
                    <i class="material-icons">people</i>
                  </span>
                  {{ getMembersCount(community) }}
                </span>
              </p>
            </div>
          </div>
          <div class="content">
            <div class="buttons">
              <button
                @click="$emit('community-users', community.id, community.name)"
                class="button is-small is-primary is-outlined"
              >
                USUÁRIOS
              </button>
              <button
                @click="$emit('community-requests', community.id, community.name)"
                class="button is-small is-primary is-outlined"
              >
                SOLICITAÇÕES
              </button>
              <button
                @click="$emit('community-ads', community.id, community.name)"
                class="button is-small is-primary is-outlined"
              >
                ANÚNCIOS
              </button>
              <button
                @click="$emit('community-history', community.id, community.name)"
                class="button is-small is-primary is-outlined"
              >
                HISTÓRICO
              </button>
              <button
                v-if="superAdmin"
                @click="$emit('delete-community', community.id, community.name)"
                class="button is-small is-danger is-outlined"
              >
                DELETAR
              </button>

              <button
                @click="toggleExpanded(community.id)"
                class="button is-small is-primary is-outlined"
                aria-label="Expand details"
              >
                <span class="icon">
                  <i class="material-icons">{{ isExpanded(community.id) ? 'expand_less' : 'expand_more' }}</i>
                </span>
              </button>
            </div>

            <!-- Expandable content -->
            <div v-if="isExpanded(community.id)" class="mt-4 pt-4" style="border-top: 1px solid #f0f0f0">
              <div class="is-left">
                <qrcode-vue :value="community.joinLink" :size="size" level="H" class="mb-3"></qrcode-vue>

                <div class="tags has-addons">
                  <span class="tag is-dark">Código de acesso</span>
                  <span class="tag is-info">{{ community.joinCode }}</span>
                </div>
              </div>
            </div>
          </div>
        </article>
      </div>
    </div>
  </div>
</template>

<script>
import QrcodeVue from 'qrcode.vue'

export default {
  props: ['superAdmin', 'communities'],
  components: {
    QrcodeVue,
  },
  data() {
    return {
      expandedCommunities: {},
      value: 'https://example.com',
      size: 300,
    }
  },
  methods: {
    toggleExpanded(communityId) {
      this.$set(this.expandedCommunities, communityId, !this.expandedCommunities[communityId])
    },
    isExpanded(communityId) {
      return Boolean(this.expandedCommunities[communityId])
    },
    getMembersCount(community) {
      // Check if members array exists and return its length
      if (Array.isArray(community.members)) {
        return community.members.length
      }
      // If members property doesn't exist or isn't an array
      return 0
    },
  },
}
</script>
