<template>
  <form @submit.prevent="submitAd" class="form">
    <!-- Debug Checkbox -->
    <div class="field">
      <div class="control">
        <label class="checkbox">
          <input type="checkbox" v-model="debug" @change="fillTestData" />
          Fill test data
        </label>
      </div>
    </div>

    <!-- User Selection -->

    <UserSelection
      :communityId="$route.params.id"
      label="Usuário do Anúncio"
      @user-loaded="handleUserLoaded"
      @user-selected="handleUserSelected"
    />

    <!-- Image Upload -->
    <div class="field">
      <label class="label">Imagens (máximo 5)</label>
      <div class="control">
        <div class="image-grid">
          <div v-for="(image, index) in selectedImages" :key="index" class="image-container">
            <img :src="image.preview" class="preview-image" />
            <button type="button" @click="removeImage(index)" class="delete-button button is-danger is-small">
              <span class="icon">
                <i class="material-icons">close</i>
              </span>
            </button>
          </div>

          <div v-if="selectedImages.length < 5" class="add-image-button" @click="openImagePicker">
            <span class="icon is-large">
              <i class="material-icons">add_photo_alternate</i>
            </span>
            <p>Adicionar Foto</p>
          </div>
        </div>
        <input ref="fileInput" type="file" accept="image/*" multiple class="is-hidden" @change="handleImageSelection" />
      </div>
    </div>

    <!-- Ad Type Selection -->
    <div class="field">
      <label class="label">Tipo de anúncio</label>
      <div class="control">
        <div class="select is-fullwidth">
          <select v-model="type">
            <option value="">Selecione o tipo</option>
            <option value="Ofereço">Ofereço</option>
            <option value="Procuro">Procuro</option>
          </select>
        </div>
      </div>
    </div>

    <!-- Purpose Selection (based on type) -->
    <div class="field" v-if="type">
      <label class="label">Finalidade</label>
      <div class="control">
        <div class="select is-fullwidth">
          <select v-model="purpose">
            <option value="">Selecione a finalidade</option>
            <template v-if="type === 'Ofereço'">
              <option value="Alugo">Alugo</option>
              <option value="Vendo">Vendo</option>
            </template>
            <template v-if="type === 'Procuro'">
              <option value="Alugo">Alugo</option>
              <option value="Compro">Compro</option>
            </template>
          </select>
        </div>
      </div>
    </div>

    <!-- Title -->
    <div class="field">
      <label class="label">Título</label>
      <div class="control">
        <input v-model="title" class="input" type="text" maxlength="30" placeholder="Título do anúncio" />
      </div>
    </div>

    <!-- Category -->
    <div class="field">
      <label class="label">Categoria</label>
      <div class="control">
        <div class="select is-fullwidth">
          <select v-model="category">
            <option value="">Selecione uma categoria</option>
            <option v-for="cat in categories" :key="cat" :value="cat">
              {{ cat }}
            </option>
          </select>
        </div>
      </div>
    </div>

    <!-- Subcategory -->
    <div class="field" v-if="category">
      <label class="label">Subcategoria</label>
      <div class="control">
        <div class="select is-fullwidth">
          <select v-model="subCategory">
            <option value="">Selecione uma subcategoria</option>
            <option v-for="sub in subcategories[category] || []" :key="sub" :value="sub">
              {{ sub }}
            </option>
          </select>
        </div>
      </div>
    </div>

    <!-- Price -->
    <div class="field">
      <label class="label">Preço</label>
      <div class="control">
        <input v-model="price" class="input" type="text" v-money="money" placeholder="R$ 0,00" />
      </div>
    </div>

    <!-- Payment Methods -->
    <div class="field">
      <label class="label">Formas de pagamento</label>
      <div class="control">
        <label class="checkbox mr-4" v-for="method in paymentOptions" :key="method.value">
          <input type="checkbox" :value="method.value" v-model="paymentMethods" />
          {{ method.label }}
        </label>
      </div>
    </div>

    <!-- Address -->
    <div class="field">
      <label class="label">Complemento</label>
      <div class="control">
        <input v-model="address" class="input" type="text" maxlength="80" placeholder="Ex: Apartamento 101" />
      </div>
    </div>

    <!-- Description -->
    <div class="field">
      <label class="label">Descrição</label>
      <div class="control">
        <textarea
          v-model="description"
          class="textarea"
          maxlength="5000"
          placeholder="Descreva os detalhes do seu anúncio..."
        ></textarea>
      </div>
    </div>

    <!-- Expiration Date -->
    <div class="field">
      <label class="label">Data de expiração (opcional)</label>
      <div class="control">
        <input v-model="expiresAt" class="input" type="date" :min="minDate" :max="maxDate" />
      </div>
    </div>

    <!-- Submit Buttons -->
    <div class="field is-grouped">
      <div class="control">
        <button type="submit" class="button is-primary" :class="{ 'is-loading': isLoading }" :disabled="!isFormValid">
          Publicar
        </button>
      </div>
      <div class="control">
        <button type="button" class="button is-light" @click="$emit('cancel')" :disabled="isLoading">Cancelar</button>
      </div>
    </div>
  </form>
</template>

<script>
import { VMoney } from 'v-money'
import { storageService } from '@/services/storageService'
import UserSelection from './widgets/UserSelection.vue'

export default {
  directives: { money: VMoney },
  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    UserSelection,
  },
  data() {
    return {
      // User selection related
      currentUserId: this.$store.getters.user.data.uid,
      currentUserData: null,
      selectedUserId: null,
      selectedUserData: null,

      debug: false,
      selectedImages: [],
      submitTriggered: false,
      title: '',
      description: '',
      address: '',
      type: '',
      purpose: '',
      category: '',
      contactInfo: '',
      subCategory: '',
      price: '',
      paymentMethods: [],
      expiresAt: '',
      money: {
        decimal: ',',
        thousands: '.',
        prefix: 'R$ ',
        precision: 2,
        masked: false,
      },
      categories: ['Serviços', 'Refeição', 'Mercado', 'Perfumaria', 'Ferramentas', 'Diversos'],
      subcategories: {
        Serviços: [
          'Beleza, Estética e Bem estar',
          'Confecções e Alfaiate',
          'Construção, Manutenção e Reforma',
          'Cuidador',
          'Decoração',
          'Educação',
          'Esportes',
          'Jardinagem e Paisagismo',
          'Transportes',
          'Outros',
        ],
        Refeição: [
          'Árabe',
          'Asiática',
          'Brasileira',
          'Burgers',
          'Doce',
          'Italiana',
          'Japonesa',
          'Marmita',
          'Mexicana',
          'Padaria',
          'Peruana',
          'Saudável',
          'Sucos',
          'Outros',
        ],
        Mercado: [
          'Alimentos',
          'Bebês e Crianças',
          'Bebidas',
          'Doces e Salgados',
          'Higiene',
          'Limpeza',
          'Pets',
          'Utilidades',
          'Outros',
        ],
        Perfumaria: ['Cabelos', 'Maquiagem', 'Perfumes', 'Bebês e Crianças', 'Higiene', 'Outros'],
        Ferramentas: [
          'Acessórios',
          'Automotivas',
          'Elétricas',
          'Construção',
          'Jardim',
          'Pintura',
          'Máquinas',
          'Organização',
          'Outros',
        ],
        Diversos: ['Academia', 'Bicicleta', 'Carro', 'Casa', 'Pessoal', 'Outros'],
      },
      paymentOptions: [
        { label: 'Dinheiro', value: 'CASH' },
        { label: 'Crédito/Débito', value: 'CC' },
        { label: 'PIX', value: 'PIX' },
        { label: 'Aceito Escambo!', value: 'ESCAMBO' },
      ],
    }
  },

  computed: {
    minDate() {
      return new Date().toISOString().split('T')[0]
    },
    maxDate() {
      const date = new Date()
      date.setDate(date.getDate() + 90)
      return date.toISOString().split('T')[0]
    },
    isFormValid() {
      return (
        this.title &&
        this.description &&
        this.category &&
        this.subCategory &&
        this.type &&
        this.purpose &&
        this.paymentMethods.length > 0 &&
        this.price
      )
    },
  },
  methods: {
    // Add these new methods
    handleUserLoaded({ userId, userData }) {
      this.currentUserId = userId
      this.currentUserData = userData
    },

    handleUserSelected({ userId, userData }) {
      this.selectedUserId = userId
      this.selectedUserData = userData
    },

    // Modify your existing submitAd method
    async submitAd() {
      // Check if form is valid first and prevent execution if already submitting
      if (!this.isFormValid || this.isLoading) {
        return
      }

      // Set the loading state at the beginning
      this.$emit('update:isLoading', true)

      try {
        this.$emit('loading', true)

        const userId = this.selectedUserId || this.currentUserId
        const communityId = this.$route.params.id
        const userData = this.selectedUserData || this.currentUserData

        const imageUrls = this.selectedImages.length > 0 ? await this.uploadImages(communityId, userId) : []

        const priceValue = parseInt(this.price.replace(/\D/g, ''))

        const ad = {
          title: this.title,
          description: this.description,
          address: this.address,
          category: this.category,
          contactInfo: userData.contactInfo || 'Não informado',
          subCategory: this.subCategory,
          type: this.type,
          purpose: this.purpose,
          paymentMethods: this.paymentMethods,
          price: priceValue,
          images: imageUrls,
          expiresAt: this.expiresAt ? new Date(this.expiresAt) : null,
          owner: userData.displayName || 'Não id', // Add this line
          ownerId: userId,
          ownerData: userData,
        }

        this.$emit('add-ad', ad)
      } catch (error) {
        console.error('Error creating ad:', error)
        this.$buefy.toast.open({
          message: 'Erro ao criar anúncio',
          type: 'is-danger',
        })
      } finally {
        this.$emit('update:isLoading', false)
      }
    },

    fillTestData() {
      if (this.debug) {
        this.title = 'Bolo de Chocolate'
        this.description = 'Bolo caseiro de chocolate'
        this.type = 'Ofereço'
        this.purpose = 'Vendo'
        this.category = 'Refeição'
        this.subCategory = 'Doce'
        this.price = 'R$ 35,00'
        this.paymentMethods = ['PIX', 'CASH']
        this.address = 'Apto 1301'

        // const date = new Date()
        // date.setDate(date.getDate() + 30)
        // this.expiresAt = date.toISOString().split('T')[0]
      } else {
        this.title = ''
        this.description = ''
        this.type = ''
        this.purpose = ''
        this.category = ''
        this.subCategory = ''
        this.price = ''
        this.paymentMethods = []
        this.address = ''
        this.expiresAt = ''
      }
    },

    openImagePicker() {
      this.$refs.fileInput.click()
    },

    async handleImageSelection(event) {
      const files = Array.from(event.target.files)

      // Check if adding new files would exceed the 5 image limit
      if (this.selectedImages.length + files.length > 5) {
        this.$buefy.toast.open({
          message: 'Máximo de 5 imagens permitido',
          type: 'is-warning',
        })
        return
      }

      // Process each selected file
      for (const file of files) {
        // Validate file type
        if (!file.type.startsWith('image/')) {
          this.$buefy.toast.open({
            message: 'Por favor, selecione apenas arquivos de imagem',
            type: 'is-danger',
          })
          continue
        }

        // Create preview
        const preview = URL.createObjectURL(file)

        // Add to selected images
        this.selectedImages.push({
          file,
          preview,
        })
      }

      // Clear input value to allow selecting the same file again
      event.target.value = ''
    },

    removeImage(index) {
      // Remove preview URL to prevent memory leaks
      URL.revokeObjectURL(this.selectedImages[index].preview)
      this.selectedImages.splice(index, 1)
    },

    async uploadImages(communityId, userId) {
      const uploadPromises = []

      for (const image of this.selectedImages) {
        try {
          // Compress image before upload
          const compressedFile = await storageService.compressImage(image.file)

          // Upload compressed image
          const uploadPromise = storageService.uploadFile(compressedFile, communityId, userId)
          uploadPromises.push(uploadPromise)
        } catch (error) {
          console.error('Error processing image:', error)
          throw error
        }
      }

      try {
        // Wait for all uploads to complete
        return await Promise.all(uploadPromises)
      } catch (error) {
        console.error('Error uploading images:', error)
        throw error
      }
    },
  },
}
</script>

<style scoped>
.form {
  max-width: 800px;
  margin: 0 auto;
}

.checkbox {
  display: inline-flex;
  align-items: center;
  margin-right: 1rem;
}

.checkbox input {
  margin-right: 0.5rem;
}

.image-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
  gap: 1rem;
  margin-bottom: 1rem;
}

.image-container {
  position: relative;
  aspect-ratio: 1;
  border-radius: 4px;
  overflow: hidden;
}

.preview-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.delete-button {
  position: absolute;
  top: 4px;
  right: 4px;
  padding: 4px;
  min-width: auto;
  height: auto;
}

.add-image-button {
  border: 2px dashed #dbdbdb;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  aspect-ratio: 1;
  padding: 1rem;
  transition: border-color 0.3s;
}

.add-image-button:hover {
  border-color: #b5b5b5;
}

.add-image-button p {
  margin-top: 0.5rem;
  font-size: 0.875rem;
  color: #7a7a7a;
}
</style>
