<template>
  <div class="kit-preview">
    <div v-for="(ad, index) in ads" :key="index" class="box mb-4">
      <div class="columns">
        <!-- Image Preview -->
        <div class="column is-3">
          <div v-if="ad.previewImages && ad.previewImages.length > 0" class="image-preview">
            <img :src="ad.previewImages[0]" alt="Preview" class="preview-image" />
            <div v-if="ad.previewImages.length > 1" class="image-count">+{{ ad.previewImages.length - 1 }}</div>
          </div>
          <div v-else-if="ad.images && ad.images.length > 0" class="image-preview">
            <img :src="ad.images[0]" alt="Preview" class="preview-image" />
            <div v-if="ad.images.length > 1" class="image-count">+{{ ad.images.length - 1 }}</div>
          </div>
          <div v-else class="no-image">
            <span class="icon is-large">
              <i class="material-icons">image_not_supported</i>
            </span>
          </div>
        </div>

        <!-- Ad Details -->
        <div class="column">
          <div class="content">
            <h4>{{ ad.title }}</h4>
            <p class="has-text-grey">{{ ad.category }} > {{ ad.subcategory }}</p>
            <p>{{ ad.description }}</p>

            <div class="tags">
              <span class="tag is-info">{{ ad.type }}</span>
              <span class="tag is-success">{{ ad.mode }}</span>
              <span v-for="(payment, paymentIndex) in ad.paymentTypes" :key="paymentIndex" class="tag is-light">
                {{ getPaymentLabel(payment) }}
              </span>
            </div>

            <p class="has-text-weight-bold">
              {{ formatPrice(ad.price) }}
            </p>
          </div>
        </div>

        <!-- Checkbox to include/exclude ad -->
        <div class="column is-narrow is-flex is-align-items-center">
          <label class="checkbox">
            <input type="checkbox" v-model="adSelections[index]" @change="updateSelectedAds" />
            <span class="ml-2">Incluir</span>
          </label>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'KitPreview',
  props: {
    ads: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      adSelections: [],
    }
  },
  watch: {
    ads: {
      immediate: true,
      handler(newAds) {
        // Initialize all checkboxes as checked when ads change
        this.adSelections = newAds.map(() => true)
        this.updateSelectedAds()
      },
    },
  },
  methods: {
    formatPrice(price) {
      // Format price as currency (e.g., 1000 -> R$ 10,00)
      return new Intl.NumberFormat('pt-BR', {
        style: 'currency',
        currency: 'BRL',
      }).format(price / 100)
    },
    getPaymentLabel(paymentType) {
      const paymentLabels = {
        CASH: 'Dinheiro',
        CC: 'Cartão',
        PIX: 'PIX',
        ESCAMBO: 'Escambo',
      }
      return paymentLabels[paymentType] || paymentType
    },
    updateSelectedAds() {
      // Emit event with currently selected ads
      this.$emit(
        'selection-changed',
        this.ads.filter((ad, index) => this.adSelections[index])
      )
    },
  },
}
</script>

<style scoped>
.image-preview {
  position: relative;
  width: 100%;
  height: 120px;
  border-radius: 4px;
  overflow: hidden;
}

.preview-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.image-count {
  position: absolute;
  bottom: 5px;
  right: 5px;
  background: rgba(0, 0, 0, 0.7);
  color: white;
  border-radius: 4px;
  padding: 2px 6px;
  font-size: 0.8rem;
}

.no-image {
  width: 100%;
  height: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f5f5f5;
  border-radius: 4px;
  color: #999;
}
</style>
