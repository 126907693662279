<template>
  <div>
    <!-- <div v-if="user.data.superAdmin">
      <h3>User Profile</h3>
      <UserProfile :user="user.data" />
    </div> -->

    <CommunityPage />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
// import UserProfile from './users/UserProfile'
import CommunityPage from './communities/index'

export default {
  name: 'Home',
  components: {
    // UserProfile,
    CommunityPage,
  },
  computed: {
    ...mapGetters({
      user: 'user',
    }),
  },
}
</script>
