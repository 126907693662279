<template>
  <div>
    <div class="tile is-ancestor is-vertical">
      <div v-for="ad in ads" :key="ad.id" class="tile is-parent">
        <article class="tile is-child box position-relative slim-box">
          <div class="columns is-vcentered">
            <!-- Image Carousel  -->
            <div class="column is-4 is-flex is-align-items-center" v-if="ad.images && ad.images.length > 0">
              <div
                class="carousel-container"
                @touchstart="handleTouchStart($event, ad.id)"
                @touchmove="handleTouchMove($event, ad.id)"
                @touchend="handleTouchEnd($event, ad.id, ad.images.length)"
                @mousedown="handleMouseDown($event, ad.id)"
                @mousemove="handleMouseMove($event, ad.id)"
                @mouseup="handleMouseUp($event, ad.id, ad.images.length)"
                @mouseleave="handleMouseUp($event, ad.id, ad.images.length)"
              >
                <div class="carousel-wrapper">
                  <div class="carousel-slides" :style="{ transform: `translateX(-${activeSlides[ad.id] * 100}%)` }">
                    <div v-for="(image, index) in ad.images" :key="`carousel-${ad.id}-${index}`" class="carousel-slide">
                      <figure class="image slim-image">
                        <img :src="image" alt="Ad image" style="object-fit: cover; height: 100%; width: 100%" />
                      </figure>
                    </div>
                  </div>
                </div>

                <!-- Carousel Indicators (Positioned over the image) -->
                <div class="carousel-indicators-container" v-if="ad.images.length > 1">
                  <div class="carousel-indicators">
                    <span
                      v-for="(_, index) in ad.images"
                      :key="`dot-${ad.id}-${index}`"
                      :class="[
                        'carousel-indicator',
                        { 'is-active has-background-primary': activeSlides[ad.id] === index },
                      ]"
                      @click="goToSlide(ad.id, index)"
                    ></span>
                  </div>
                </div>
              </div>
            </div>

            <!-- Ad Details  -->
            <div class="column" :class="{ 'is-8': ad.images && ad.images.length > 0 }">
              <div class="ad-header">
                <p class="title is-5 mb-1">{{ ad.title }}</p>
                <p class="subtitle is-7 mb-2">Usuário: {{ ad.owner }}</p>
              </div>

              <div class="content compact-content">
                <p class="has-text-grey mb-2 description-text">{{ ad.description }}</p>
                <p class="has-text-weight-bold is-primary is-size-5 mb-2">{{ formatPrice(ad.price) }}</p>

                <div class="tags are-small">
                  <!-- Created At Tag -->
                  <span v-if="ad.createdAt" class="tag">
                    <strong>Criado:</strong>&nbsp;
                    <time>{{ ad.createdAt.toDate() | formatDate }}</time>
                  </span>

                  <!-- Expires At Tag -->
                  <span v-if="ad.expiresAt" class="tag is-warning">
                    <strong>Expira:</strong>&nbsp;
                    <time>{{ ad.expiresAt.toDate() | formatDate }}</time>
                  </span>

                  <!-- Expired Tag -->
                  <span v-if="isAdExpired(ad.expiresAt)" class="tag is-danger">
                    <strong>Expirado</strong>
                  </span>

                  <!-- Ad Type Tags -->
                  <span class="tag is-primary">
                    {{ ad.type }}
                  </span>

                  <span v-if="ad.mode" class="tag is-primary is-light">
                    {{ ad.mode }}
                  </span>
                </div>

                <!-- Additional information -->
                <div class="is-size-7 has-text-grey mt-1 category-info" v-if="ad.category || ad.subcategory">
                  <span v-if="ad.category"><strong>Categoria:</strong> {{ ad.category }}</span>
                  <span v-if="ad.subcategory" class="ml-2"><strong>Subcategoria:</strong> {{ ad.subcategory }}</span>
                </div>
              </div>
            </div>
          </div>

          <!-- Delete button positioned at bottom right -->
          <div class="delete-button-container">
            <button @click="$emit('delete-ad', ad.id)" class="delete-button" title="Deletar anúncio">
              <span class="material-icons icon-small">delete</span>
            </button>
          </div>
        </article>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['communityId', 'ads'],
  data() {
    return {
      activeSlides: {},
      touchStartX: {},
      touchEndX: {},
      mouseStartX: {},
      mouseEndX: {},
      swipeThreshold: 50,
      isDragging: {},
    }
  },
  created() {
    // Initialize the active slide for each ad
    if (this.ads) {
      this.ads.forEach((ad) => {
        this.$set(this.activeSlides, ad.id, 0)
        this.$set(this.isDragging, ad.id, false)
      })
    }
  },
  watch: {
    ads: {
      immediate: true,
      handler(newAds) {
        if (newAds) {
          newAds.forEach((ad) => {
            if (!this.activeSlides[ad.id]) {
              this.$set(this.activeSlides, ad.id, 0)
              this.$set(this.isDragging, ad.id, false)
            }
          })
        }
      },
    },
  },
  methods: {
    formatPrice(number) {
      const price = number / 100
      const text = new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(price)
      return text
    },
    isAdExpired(expiresAt) {
      if (!expiresAt) return false // If no expiration date, assume not expired
      const now = new Date()
      return expiresAt.toDate() < now // Check if expiration date is in the past
    },
    goToSlide(adId, index) {
      this.activeSlides[adId] = index
    },
    // Touch events for mobile
    handleTouchStart(event, adId) {
      this.touchStartX[adId] = event.touches[0].clientX
      this.isDragging[adId] = true
    },
    handleTouchMove(event, adId) {
      if (!this.isDragging[adId]) return
      this.touchEndX[adId] = event.touches[0].clientX
    },
    handleTouchEnd(event, adId, totalSlides) {
      if (!this.isDragging[adId]) return
      this.processSwipe(this.touchStartX[adId], this.touchEndX[adId], adId, totalSlides)
      this.isDragging[adId] = false
    },
    // Mouse events for desktop
    handleMouseDown(event, adId) {
      // Prevent default to avoid text selection during drag
      event.preventDefault()
      this.mouseStartX[adId] = event.clientX
      this.isDragging[adId] = true
    },
    handleMouseMove(event, adId) {
      if (!this.isDragging[adId]) return
      this.mouseEndX[adId] = event.clientX
    },
    handleMouseUp(event, adId, totalSlides) {
      if (!this.isDragging[adId]) return
      this.processSwipe(this.mouseStartX[adId], this.mouseEndX[adId], adId, totalSlides)
      this.isDragging[adId] = false
    },
    // Common swipe processing for both touch and mouse events
    processSwipe(startX, endX, adId, totalSlides) {
      const swipeDistance = startX - endX

      // Check if swipe is significant and we have both start and end points
      if (startX && endX && Math.abs(swipeDistance) > this.swipeThreshold) {
        // Swipe left (next)
        if (swipeDistance > 0 && this.activeSlides[adId] < totalSlides - 1) {
          this.activeSlides[adId]++
        }
        // Swipe right (previous)
        else if (swipeDistance < 0 && this.activeSlides[adId] > 0) {
          this.activeSlides[adId]--
        }
      }
    },
  },
}
</script>

<style scoped>
/* Slim box design */
.slim-box {
  padding: 1rem;
  margin-bottom: 0.75rem;
}

/* Reduced height image */
.slim-image {
  height: 220px; /* Reduced from 350px */
  width: 100%;
}

/* Compact content styling */
.compact-content {
  font-size: 0.95rem;
}

.description-text {
  max-height: 4.5em;
  overflow: hidden;
  display: -webkit-box;
  line-clamp: 3;
  -webkit-box-orient: vertical;
}

.category-info {
  display: flex;
  flex-wrap: wrap;
}

.ad-header {
  margin-bottom: 0.5rem;
}

/* Carousel styles */
.carousel-container {
  position: relative;
  width: 100%;
  overflow: hidden;
  border-radius: 4px; /* Slightly reduced from 6px */
  box-shadow: 0 0.25em 0.5em -0.125em rgba(10, 10, 10, 0.1); /* Reduced shadow */
  cursor: grab;
}

.carousel-container:active {
  cursor: grabbing;
}

.carousel-wrapper {
  width: 100%;
  overflow: hidden;
}

.carousel-slides {
  display: flex;
  transition: transform 0.3s ease;
}

.carousel-slide {
  min-width: 100%;
}

/* Smaller indicators */
.carousel-indicators-container {
  position: absolute;
  bottom: 0.5rem;
  left: 0;
  right: 0;
  z-index: 2;
}

.carousel-indicators {
  display: flex;
  justify-content: center;
  gap: 0.4rem;
}

.carousel-indicator {
  width: 0.4rem;
  height: 0.4rem;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.7);
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.3);
}

.carousel-indicator.is-active {
  background-color: var(--bulma-primary, #3273dc);
  transform: scale(1.2);
}

/* Position relative for the parent container */
.position-relative {
  position: relative;
}

/* Style for delete button container */
.delete-button-container {
  position: absolute;
  bottom: 0.5rem;
  right: 0.5rem;
  z-index: 1;
}

/* Make tags more compact */
.tags.are-small .tag {
  font-size: 0.65rem;
  height: 1.5em;
  padding-left: 0.5em;
  padding-right: 0.5em;
}
</style>
