<template>
  <section class="section">
    <h2>
      <router-link to="/">{{ this.$route.params.name }}</router-link> &gt; Anúncios
    </h2>

    <div class="mb-4">
      <button v-if="user.data.superAdmin" @click="showAddAdForm" class="button is-primary mr-4 mb-2">
        <span class="icon">
          <i class="material-icons">add</i>
        </span>
        <span>Adicionar Anúncio</span>
      </button>

      <button v-if="user.data.superAdmin" @click="showAddKitForm" class="button is-primary mb-2">
        <span class="icon">
          <i class="material-icons">inventory_2</i>
        </span>
        <span>Adicionar Kit</span>
      </button>
    </div>

    <ListAds :communityId="this.$route.params.id" :ads="ads" @delete-ad="deleteAd" />

    <!-- Add Ad Modal -->
    <div class="modal" :class="{ 'is-active': isAddAdModalActive }">
      <div class="modal-background" @click="closeAddAdForm"></div>
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">Adicionar Anúncio</p>
          <button class="delete" aria-label="close" @click="closeAddAdForm"></button>
        </header>
        <section class="modal-card-body">
          <AddAd @add-ad="createAd" @cancel="closeAddAdForm" :isLoading="isLoading" />
        </section>
      </div>
    </div>

    <!-- Add Kit Modal -->
    <div class="modal" :class="{ 'is-active': isAddKitModalActive }">
      <div class="modal-background" @click="closeAddKitForm"></div>
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">Adicionar Kit de Anúncios</p>
          <button class="delete" aria-label="close" @click="closeAddKitForm"></button>
        </header>
        <section class="modal-card-body">
          <AddKit
            :communityId="this.$route.params.id"
            :isLoading="isLoading"
            @cancel="closeAddKitForm"
            @update:isLoading="setLoading"
            @kit-created="onKitCreated"
          />
        </section>
      </div>
    </div>
  </section>
</template>

<script>
import { mapGetters } from 'vuex'
import { db, Timestamp, serverTimestamp } from '@/firecontainer'
import AddAd from './AddAd'
import AddKit from './AddKit'
import ListAds from './ListAds'
import { storageService } from '@/services/storageService'

export default {
  name: 'CommunityAdsPage',
  components: { AddAd, ListAds, AddKit },
  data() {
    return {
      ads: [],
      isAddAdModalActive: false,
      isAddKitModalActive: false,
      isLoading: false,
    }
  },
  computed: {
    ...mapGetters({
      user: 'user',
    }),
  },
  firestore() {
    return {
      ads: db.collection('communities').doc(this.$route.params.id).collection('ads').orderBy('createdAt', 'desc'),
    }
  },
  methods: {
    setLoading(value) {
      this.isLoading = value
    },

    showAddAdForm() {
      this.isAddAdModalActive = true
    },

    closeAddAdForm() {
      this.isAddAdModalActive = false
    },

    showAddKitForm() {
      this.isAddKitModalActive = true
    },

    closeAddKitForm() {
      this.isAddKitModalActive = false
    },

    onKitCreated() {
      this.closeAddKitForm()
      console.log('Kit criado com sucesso!')
    },

    async createAd(ad) {
      try {
        this.isLoading = true

        const newAd = {
          title: ad.title,
          description: ad.description,
          address: ad.address,
          category: ad.category,
          contactInfo: ad.contactInfo || ad.ownerData.contactInfo || 'Não informado',
          subcategory: ad.subCategory,
          type: ad.type,
          mode: ad.purpose,
          paymentTypes: ad.paymentMethods,
          price: ad.price,
          images: ad.images || [],
          expiresAt: ad.expiresAt ? Timestamp.fromDate(ad.expiresAt) : null,
          createdAt: serverTimestamp(),
          owner: ad.owner,
          ownerId: ad.ownerId,
          updatedAt: serverTimestamp(),
        }

        await db.collection('communities').doc(this.$route.params.id).collection('ads').add(newAd)

        this.closeAddAdForm()
        console.log('Anúncio criado com sucesso!')
      } catch (error) {
        console.error('Error creating ad:', error)
      } finally {
        this.isLoading = false
      }
    },

    async deleteAd(adId) {
      if (confirm('Tem certeza que deseja deletar esse anúncio? Esta operação não pode ser desfeita')) {
        try {
          this.isLoading = true

          // First, get the ad data to access its images
          const adDoc = await db.collection('communities').doc(this.$route.params.id).collection('ads').doc(adId).get()

          const adData = adDoc.data()

          // Delete images from storage if they exist
          if (adData && adData.images && adData.images.length > 0) {
            try {
              await Promise.all(adData.images.map((imageUrl) => storageService.deleteFile(imageUrl)))
            } catch (error) {
              console.error('Error deleting images:', error)
              // Continue with ad deletion even if image deletion fails
            }
          }

          // Delete the ad document
          await db.collection('communities').doc(this.$route.params.id).collection('ads').doc(adId).delete()

          console.log('Anúncio deletado com sucesso!')
        } catch (error) {
          console.error('Error deleting ad:', error)
        } finally {
          this.isLoading = false
        }
      }
    },
  },
}
</script>
