<template>
  <div class="community-filter-search mb-4">
    <div class="is-flex is-align-items-center">
      <!-- Filter Dropdown First -->
      <div class="mr-2">
        <div class="dropdown" :class="{ 'is-active': isDropdownActive }">
          <div class="dropdown-trigger">
            <button
              class="button"
              :class="{ 'is-primary': currentFilter !== 'default' }"
              aria-haspopup="true"
              aria-controls="dropdown-menu"
              @click="toggleDropdown"
            >
              <span class="icon is-small">
                <i class="material-icons">filter_list</i>
              </span>
              <span>{{ getFilterLabel }}</span>
              <span class="icon is-small">
                <i class="material-icons">arrow_drop_down</i>
              </span>
            </button>
          </div>
          <div class="dropdown-menu" id="dropdown-menu" role="menu">
            <div class="dropdown-content">
              <a
                class="dropdown-item"
                :class="{ 'has-background-primary-light has-text-primary': currentFilter === 'default' }"
                @click="setFilter('default')"
              >
                Padrão
              </a>
              <a
                class="dropdown-item"
                :class="{ 'has-background-primary-light has-text-primary': currentFilter === 'name' }"
                @click="setFilter('name')"
              >
                Nome
              </a>
              <a
                class="dropdown-item"
                :class="{ 'has-background-primary-light has-text-primary': currentFilter === 'members' }"
                @click="setFilter('members')"
              >
                Membros
              </a>
            </div>
          </div>
        </div>
      </div>

      <!-- Search Field Second -->
      <div class="is-flex-grow-1">
        <div class="field is-fullwidth">
          <div class="control has-icons-left">
            <input
              class="input"
              type="text"
              placeholder="Buscar comunidades"
              v-model="searchQuery"
              @input="emitSearchAndFilter"
            />
            <span class="icon is-small is-left">
              <i class="material-icons">search</i>
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CommunityFilterBar',
  data() {
    return {
      currentFilter: 'default',
      isDropdownActive: false,
      searchQuery: '',
    }
  },
  computed: {
    getFilterLabel() {
      switch (this.currentFilter) {
        case 'name':
          return 'Nome'
        case 'members':
          return 'Membros'
        default:
          return ''
      }
    },
  },
  methods: {
    toggleDropdown() {
      this.isDropdownActive = !this.isDropdownActive
    },
    setFilter(filterType) {
      this.currentFilter = filterType
      this.isDropdownActive = false // Close dropdown after selection
      this.emitSearchAndFilter()
    },
    emitSearchAndFilter() {
      // Emit both the search query and current filter
      this.$emit('search-filter-changed', {
        filter: this.currentFilter,
        search: this.searchQuery,
      })
    },
    documentClick(e) {
      const dropdown = this.$el.querySelector('.dropdown')
      if (dropdown && !dropdown.contains(e.target)) {
        this.isDropdownActive = false
      }
    },
  },
  // Close dropdown when clicking outside
  mounted() {
    document.addEventListener('click', this.documentClick)
  },
  beforeDestroy() {
    document.removeEventListener('click', this.documentClick)
  },
}
</script>

<style scoped>
.community-filter-search {
  width: 100%;
}

.is-fullwidth {
  width: 100%;
}
</style>
