<template>
  <section class="section">
    <AddCommunity v-if="user.data.superAdmin" @add-community="createCommunity" />

    <!-- Filter and Search Bar -->
    <CommunityFilterBar v-if="user.data.superAdmin" @search-filter-changed="applySearchAndFilter" />

    <ListCommunities
      :superAdmin="user.data.superAdmin"
      @community-users="routeToCommunityUsers"
      @community-ads="routeToCommunityAds"
      @community-requests="routeToCommunityRequests"
      @community-history="routeToCommunityHistory"
      @delete-community="deleteCommunity"
      :communities="filteredCommunities"
    />

    <!-- No results message -->
    <div v-if="filteredCommunities.length === 0 && rawCommunities.length > 0" class="notification is-gray">
      Nenhuma comunidade encontrada com os critérios de busca atuais.
    </div>
  </section>
</template>

<script>
import { mapGetters } from 'vuex'
import { db, serverTimestamp, GeoPoint, FieldPath } from '@/firecontainer'
import AddCommunity from './AddCommunity'
import ListCommunities from './ListCommunities'
import CommunityFilterBar from './CommunityFilterBar'

export default {
  name: 'CommunityPage',
  data() {
    return {
      currentFilter: 'default',
      searchQuery: '',
      rawCommunities: [],
    }
  },
  components: {
    AddCommunity,
    ListCommunities,
    CommunityFilterBar,
  },
  computed: {
    ...mapGetters({
      user: 'user',
    }),
    // Compute filtered communities based on the current filter and search
    filteredCommunities() {
      // First apply search filter if there is a search query
      let filtered = this.rawCommunities

      if (this.searchQuery && this.searchQuery.trim() !== '') {
        const query = this.normalizeText(this.searchQuery.trim())
        filtered = filtered.filter(
          (community) =>
            this.normalizeText(community.name).includes(query) ||
            (community.description && this.normalizeText(community.description).includes(query)) ||
            (community.address && this.normalizeText(community.address).includes(query))
        )
      }

      // Then apply sorting filter
      if (this.currentFilter === 'default') {
        return filtered
      }

      // Create a copy to avoid mutating the filtered data
      const sorted = [...filtered]

      if (this.currentFilter === 'name') {
        // Sort by community name alphabetically
        return sorted.sort((a, b) => {
          return a.name.localeCompare(b.name, 'pt-BR')
        })
      }

      if (this.currentFilter === 'members') {
        // Sort by number of members (descending)
        return sorted.sort((a, b) => {
          const countA = Array.isArray(a.members) ? a.members.length : 0
          const countB = Array.isArray(b.members) ? b.members.length : 0
          return countB - countA // Descending order
        })
      }

      return filtered
    },
  },
  watch: {
    user: {
      immediate: true,
      handler(u) {
        if (u.data.superAdmin) {
          this.$bind('rawCommunities', db.collection('communities').orderBy('createdAt', 'desc'))
        } else {
          if (Array.isArray(u.data.admin) && u.data.admin.length > 0) {
            this.$bind('rawCommunities', db.collection('communities').where(FieldPath.documentId(), 'in', u.data.admin))
          } else {
            console.warn('User is not admin or admin data is empty')
          }
        }
      },
    },
  },
  methods: {
    // Method to handle search and filter changes from the filter bar
    applySearchAndFilter(data) {
      this.currentFilter = data.filter
      this.searchQuery = data.search
    },
    async createCommunity(community) {
      await db.collection('communities').add({
        name: community.name,
        description: community.description,
        address: community.address,
        geolocation: new GeoPoint(community.location.latitude, community.location.longitude),
        createdAt: serverTimestamp(),
        updatedAt: serverTimestamp(),
      })
    },

    async deleteCommunity(communityId) {
      this.$confirm({
        message: 'Tem certeza que deseja excluir esta comunidade?',
        button: {
          no: 'NÃO',
          yes: 'SIM',
        },
        callback: async (confirm) => {
          if (confirm) {
            await db.collection('communities').doc(communityId).delete()
          }
        },
      })
    },

    async routeToCommunityUsers(communityId, communityName) {
      this.$router.push({
        name: 'CommunityUsers',
        params: { id: communityId, name: communityName },
      })
    },

    async routeToCommunityAds(communityId, communityName) {
      this.$router.push({
        name: 'CommunityAds',
        params: { id: communityId, name: communityName },
      })
    },

    async routeToCommunityRequests(communityId, communityName) {
      this.$router.push({
        name: 'CommunityRequests',
        params: { id: communityId, name: communityName },
      })
    },
    async routeToCommunityHistory(communityId, communityName) {
      this.$router.push({
        name: 'CommunityHistory',
        params: { id: communityId, name: communityName },
      })
    },

    // Helper method to normalize text for accent-insensitive search
    normalizeText(text) {
      if (!text) return ''
      return text
        .toLowerCase()
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '') // Remove diacritics (accents)
    },
  },
}
</script>
