<template>
  <section class="section">
    <h2>Feedback dos Usuários</h2>

    <div class="tile is-ancestor is-vertical">
      <div v-if="!feedback.length" class="card">
        <div class="card-content">
          <div class="content">Nenhum feedback disponível.</div>
        </div>
      </div>

      <div v-for="item in feedback" :key="item.id" class="card mb-4">
        <div class="card-content">
          <div class="content">
            <div class="columns is-multiline">
              <!-- Status Checkbox -->
              <div class="column is-12">
                <div class="field">
                  <label class="checkbox">
                    <input type="checkbox" :checked="item.status === 'closed'" @change="$emit('update-status', item)" />
                    <span class="ml-2">
                      Status:
                      <span
                        class="tag"
                        :class="{
                          'is-success': item.status === 'closed',
                          'is-warning': !item.status || item.status === 'open',
                        }"
                      >
                        {{ !item.status || item.status === 'open' ? 'Em Aberto' : 'Resolvido' }}
                      </span>
                    </span>
                  </label>
                </div>
              </div>

              <!-- Community Info -->
              <div class="column is-12">
                <p class="title is-5">Comunidade: {{ item.communityName }}</p>
                <p class="subtitle is-6">ID da Comunidade: {{ item.communityId }}</p>
              </div>

              <!-- User Info -->
              <div class="column is-12">
                <div class="box">
                  <p><strong>Usuário:</strong> {{ item.userName }}</p>
                  <p><strong>Email:</strong> {{ item.userEmail }}</p>
                  <p><strong>Contato:</strong> {{ formatPhoneNumber(item.userContact, 'BR') }}</p>
                  <p><strong>ID do Usuário:</strong> {{ item.userId }}</p>
                </div>
              </div>

              <!-- Message -->
              <div class="column is-12">
                <div class="box">
                  <p class="has-text-weight-bold mb-2">Mensagem:</p>
                  <p class="has-text-dark">{{ item.message }}</p>
                </div>
              </div>

              <!-- Timestamp -->
              <div class="column is-12">
                <time class="tag is-info">
                  {{ item.createdAt?.toDate() | formatDate }}
                </time>
                <template v-if="item.updatedAt">
                  <span class="mx-2">•</span>
                  <time class="tag is-info is-light"> Atualizado: {{ item.updatedAt.toDate() | formatDate }} </time>
                </template>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'ListFeedback',
  props: {
    feedback: {
      type: Array,
      required: true,
    },
    formatPhoneNumber: {
      type: Function,
      required: true,
    },
  },
}
</script>

<style scoped>
.card {
  transition: all 0.3s ease;
}

.card:hover {
  box-shadow: 0 2px 15px rgba(0, 0, 0, 0.1);
}

.box {
  background-color: #f8f9fa;
  margin-bottom: 1rem;
}

.checkbox {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.checkbox input {
  cursor: pointer;
}
</style>
