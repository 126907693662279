// Import the local images
import cokeImg1 from '@/assets/kit-images/coke/1.jpeg'
import cokeImg2 from '@/assets/kit-images/coke/2.jpeg'
import kitkatImg1 from '@/assets/kit-images/kitkat/1.jpeg'
import kitkatImg2 from '@/assets/kit-images/kitkat/2.jpeg'
import candyImg1 from '@/assets/kit-images/candy/1.jpeg'
import candyImg2 from '@/assets/kit-images/candy/2.jpeg'
import paperImg1 from '@/assets/kit-images/paper/1.jpeg'
import paperImg2 from '@/assets/kit-images/paper/2.jpeg'
import riceImg1 from '@/assets/kit-images/rice/1.jpeg'
import riceImg2 from '@/assets/kit-images/rice/2.jpeg'
import shampooImg1 from '@/assets/kit-images/shampoo/1.jpeg'
import shampooImg2 from '@/assets/kit-images/shampoo/2.jpeg'
import detergentImg1 from '@/assets/kit-images/detergent/1.jpeg'
import detergentImg2 from '@/assets/kit-images/detergent/2.jpeg'


// Import Kit Ghost images
import airpodsImg1 from '@/assets/kit-images/airpods/1.jpeg'
import airpodsImg2 from '@/assets/kit-images/airpods/2.jpeg'
import airpodsImg3 from '@/assets/kit-images/airpods/3.jpeg'
import kindleImg1 from '@/assets/kit-images/kindle/1.jpeg'
import kindleImg2 from '@/assets/kit-images/kindle/2.jpeg'
import kindleImg3 from '@/assets/kit-images/kindle/3.jpeg'
import marmitinhaImg1 from '@/assets/kit-images/marmitinha/1.jpeg'

export const kits = {
  kit1: {
    name: 'Kit Mercado',
    ads: [
      {
        title: 'Coca Cola Tradicional 200ml',
        description: '2 Coca Cola tradicional 200ml. Tamanho perfeito e geladinhas!',
        category: 'Mercado',
        subcategory: 'Bebidas',
        type: 'Ofereço',
        mode: 'Vendo',
        paymentTypes: ['PIX', 'CASH'],
        price: 500,
        address: '',
        imageFiles: [cokeImg1, cokeImg2],
        expiresAt: new Date(Date.now() + 10 * 24 * 60 * 60 * 1000)
      },
      {
        title: 'KitKat',
        description: 'Deu vontade de chocolate? Tenho 3 Kit-Kats para você! Esse chocolate é bom demais.',
        category: 'Mercado',
        subcategory: 'Doces e Salgados',
        type: 'Ofereço',
        mode: 'Vendo',
        paymentTypes: ['PIX', 'CASH'],
        price: 1200,
        address: '',
        imageFiles: [kitkatImg1, kitkatImg2],
        expiresAt: new Date(Date.now() + 20 * 24 * 60 * 60 * 1000)
      },
      {
        title: 'Shampoo Seda',
        description: 'Shampoo detox a base de chá verde e cítricos. Produto lacrado em plástico. Tamanho 325ml.',
        category: 'Perfumaria',
        subcategory: 'Cabelos',
        type: 'Ofereço',
        mode: 'Vendo',
        paymentTypes: ['PIX', 'CASH'],
        price: 1150,
        address: '',
        imageFiles: [shampooImg1, shampooImg2],
        expiresAt: new Date(Date.now() + 30 * 24 * 60 * 60 * 1000)
      },
      {
        title: 'Detergente Ypê Neutro',
        description: 'Detergente! Precisando lavar a louça e ficou sem, só chamar.',
        category: 'Mercado',
        subcategory: 'Limpeza',
        type: 'Ofereço',
        mode: 'Vendo',
        paymentTypes: ['PIX', 'CASH'],
        price: 300,
        address: '',
        imageFiles: [detergentImg1, detergentImg2],
        expiresAt: new Date(Date.now() + 30 * 24 * 60 * 60 * 1000)
      },
      {
        title: 'Arroz com Brócolis',
        description: 'Arroz com brócolis Tio João. É muito fácil de fazer e fica bem gostoso. Saco de 250g, ideal para 4 pessoas.',
        category: 'Mercado',
        subcategory: 'Alimentos',
        type: 'Ofereço',
        mode: 'Vendo',
        paymentTypes: ['PIX', 'CASH'],
        price: 1250,
        address: '',
        imageFiles: [riceImg1, riceImg2],
        expiresAt: new Date(Date.now() + 40 * 24 * 60 * 60 * 1000)
      },
      {
        title: 'Jujuba',
        description: '2 pacotes de deliciosas gomas sortidas! 100g - o suficiente para adoçar o seu dia.',
        category: 'Mercado',
        subcategory: 'Doces e Salgados',
        type: 'Ofereço',
        mode: 'Vendo',
        paymentTypes: ['PIX', 'CASH'],
        price: 1080,
        address: '',
        imageFiles: [candyImg1, candyImg2],
        expiresAt: new Date(Date.now() + 15 * 24 * 60 * 60 * 1000)
      },
      {
        title: 'Papel Toalha Snob',
        description: 'Papel toalha para o dia a dia. 1 pacote com 2 rolos da marca Snob, bem resistente.',
        category: 'Mercado',
        subcategory: 'Utilidades',
        type: 'Ofereço',
        mode: 'Vendo',
        paymentTypes: ['PIX', 'CASH'],
        price: 550,
        address: '',
        imageFiles: [paperImg1, paperImg2],
        expiresAt: new Date(Date.now() + 10 * 24 * 60 * 60 * 1000)
      }
    ]
  },
  kitGhost: {
    name: 'Kit Ghost',
    ads: [
      {
        title: 'Apple AirPods',
        description: 'Fone Apple Airpods geração 1. O fone está bem conservado e a duração da bateria fora da caixinha esta em torno de 1h',
        category: 'Diversos',
        subcategory: 'Pessoal',
        type: 'Ofereço',
        mode: 'Vendo',
        paymentTypes: ['PIX', 'CASH'],
        price: 60000,
        address: '',
        imageFiles: [airpodsImg1, airpodsImg2, airpodsImg3],
        expiresAt: null
      },
      {
        title: 'Kindle Paperwhite 10ª Geração',
        description: 'Kindle Paperwhite com 8GB de armazenamento, iluminação ajustável e à prova d\'água. Em ótimo estado.',
        category: 'Diversos',
        subcategory: 'Pessoal',
        type: 'Ofereço',
        mode: 'Vendo',
        paymentTypes: ['PIX', 'CASH'],
        price: 35000,
        address: '',
        imageFiles: [kindleImg1, kindleImg2, kindleImg3],
        expiresAt: null
      },

      {
        title: 'Procuro Marmitinha Saudável',
        description: 'Estou procurando marmitas saudáveis para o almoço durante a semana. Prefiro opções com bastante vegetais e proteínas magras.',
        category: 'Refeição',
        subcategory: 'Marmita',
        type: 'Procuro',
        mode: 'Compro',
        paymentTypes: ['PIX', 'CASH'],
        price: 1600,
        address: '',
        imageFiles: [marmitinhaImg1],
        expiresAt: null
      }
      
    ]
  }
}
